<template>
  <div
    :class="{
      'pl-14 pr-14 pt-2': !paddingless
    }"
  >
    <div v-if="showMetadata" class="flex gap-5 items-center pb-2">
      <div class="flex gap-2 items-center">
        <fw-avatar class="mr-1.5 flex-shrink-0" size="2xs" :user="users[page.user_key]" />
        <div class="font-medium text-gray-800">
          {{ users[page.user_key]?.name ?? 'Sem autor' }}
        </div>
      </div>
      <div class="text-sm text-gray-500">{{ $t('created_at') }} {{ page.created_date | formatDateTime }}</div>
      <div class="flex-1"></div>
      <div
        class="comments-header-wrapper inline-flex items-center text-xs text-gray-500 opacity-0 transition-all gap-2 text-right"
      >
        <b-tooltip label="Todos os comentários" type="is-light">
          <div class="flex gap-1 cursor-pointer hover:text-primary" @click="$emit('sidebar', 'comments')">
            <fw-icon-discuss class="w-4 h-4" />
            <span class="block_comments w-5 inline-block text-left" data-id="document">0</span>
          </div>
        </b-tooltip>
        <fw-tag size="xs" :type="statusColors[status]">{{ $t('status.' + status) }}</fw-tag>
      </div>
      <fw-button v-if="showPreviewButton" type="xlight" size="xs" icon="eye" @click.native="$emit('preview')">
        {{ $t('preview') }}
      </fw-button>
      <fw-button v-if="showEditButton" type="xlight" size="xs" icon="edit" @click.native="$emit('edit')">
        {{ $t('edit') }}
      </fw-button>
    </div>
    <div v-if="showTitle" class="flex">
      <h1
        class="text-3xl pt-2 flex-1"
        :class="{
          'font-medium text-gray-300': page?.title == null,
          'font-bold text-gray-800': page?.title != null
        }"
      >
        <v-clamp autoresize :max-lines="3">{{ page?.title ?? 'Sem título' }}</v-clamp>
      </h1>
    </div>
    <PanelPageSubpages
      v-if="page.subpages?.length && showSubpages"
      :page="page"
      :users="users"
      :show-title="showTitle"
      @open="$emit('open', $event)"
    >
    </PanelPageSubpages>
  </div>
</template>

<script>
import PanelPageSubpages from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPageSubpages'
export default {
  name: 'BlockPageHeader',
  components: {
    PanelPageSubpages
  },
  props: {
    page: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Object,
      default: () => {}
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showMetadata: {
      type: Boolean,
      default: true
    },
    showEditButton: {
      type: Boolean,
      default: false
    },
    showSubpages: {
      type: Boolean,
      default: true
    },
    showPreviewButton: {
      type: Boolean,
      default: false
    },
    version: {
      type: Object,
      default: () => {}
    },
    paddingless: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      statusColors: {
        draft: 'light-orange',
        published: 'light-primary',
        closed: 'light-gray',
        archived: 'light-blue'
      }
    }
  },
  computed: {
    status() {
      return this.version?.state
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "no_title": "Sem título",
    "created_at": "Página criada:",
    "edit": "Editar",
    "preview": "Pré-visualizar",
    "status": {
      "draft": "Rascunho",
      "published": "Publicado",
      "closed": "Fechado",
      "archived": "Arquivado"
    }
  },
  "en": {
    "no_title": "No title",
    "created_at": "Created at",
    "edit": "Edit",
    "preview": "Preview",
    "status": {
      "draft": "Draft",
      "published": "Published",
      "closed": "Closed",
      "archived": "Archived"
    }
  }
}
</i18n>
