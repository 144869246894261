<template>
  <div style="display: contents">
    <fw-content-loader v-if="loading" />
    <template v-else>
      <BlockPageHeader
        v-if="showHeader"
        :page="page"
        :users="users"
        :show-edit-button="permissions.write"
        :version="version"
        :paddingless="false"
        class="mb-5"
        @open="$emit('open', $event)"
        @edit="$emit('edit', page)"
      />
      <PanelPagesContentTranslator
        v-if="translator && version && !isOriginal"
        :key="`${version.key}translator${version.state}`"
        :ref="`page_content_${version.key}`"
        :page-key="page.key"
        :page="page"
        :show-title="showTitle"
        :version-key="version.key"
        :version="version"
        :original-key="originalKey"
        :permissions="permissions"
        :users="users"
        :comments-document="comments['document']"
        :comments="comments"
        :active-block="activeBlock"
        :editable="false"
        :show-edit-button="permissions.write"
        @sidebar="$emit('sidebar', $event)"
        @edit="$emit('edit', page)"
        class="main-editor min-h-full max-h-full"
        :class="{
          rtl: isRTL
        }"
        v-on="$listeners"
      />
      <PanelPagesContentPreviewPage
        v-else-if="provider"
        :key="version?.key"
        :ref="`page_content_${version?.key}`"
        class="px-11"
        :collaboration-provider="provider"
        :page-number="0"
        :editable="false"
        :show-title="showTitle"
        :page="page"
        :version="version"
        :show-chapters="showChapters"
        :show-comment-hightlight="showCommentHightlight"
        :borderless="borderless"
      />
      <div v-else-if="!publishedVersion" class="pb-10 pt-5 text-center text-sm text-gray-500">Página não publicada</div>
    </template>
  </div>
</template>

<script>
import { HocuspocusProvider } from '@hocuspocus/provider'
import PanelPagesContentPreviewPage from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagesContentPreviewPage'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import BlockPageHeader from '@/fw-modules/fw-core-vue/pages/components/blocks/BlockPageHeader'
import PanelPagesContentTranslator from '@/fw-modules/fw-core-vue/pages/components/panels/PanelPagesContentTranslator'
export default {
  components: {
    PanelPagesContentPreviewPage,
    BlockPageHeader,
    PanelPagesContentTranslator
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    version: {
      type: Object
    },
    users: {
      type: Object,
      default: () => {}
    },
    permissions: {
      type: Object,
      default: () => {}
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showChapters: {
      type: Boolean,
      default: false
    },
    showCommentHightlight: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    },
    isRTL: {
      type: Boolean,
      default: false
    },
    comments: {
      type: Object,
      required: true
    },
    activeBlock: {
      type: Object,
      default: () => {}
    },
    originalKey: {
      type: String,
      required: true
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loading: true,
      provider: null
    }
  },

  computed: {
    userToken() {
      return this.$store.state.session.token
    },
    publishedVersion() {
      return this.page?.versions?.find(v => v.state == 'published')
    },
    lang() {
      return this.$store.state.language ?? 'pt'
    },
    translator() {
      return process.env.VUE_APP_KEY == 'mondaecus'
    },
    isOriginal() {
      return this.version.is_original
    }
  },

  watch: {
    userToken() {
      //prepare editor
      if (this.provider == null) {
        this.prepareProvider()
      }
    }
  },

  created() {
    if (this.version) {
      this.prepareProvider()
    }
  },

  methods: {
    prepareProvider() {
      if (!this.userToken || this.provider || !this.version) return
      console.log('prepareProvider', this.userToken, this.provider, this.version)
      // let self = this
      this.provider = new HocuspocusProvider({
        url: FwEnvConfig.apiUrlHocusPocus,
        name: `pages:${this.page.key}:${this.version.key}:0`,
        token: this.userToken,
        onAuthenticationFailed: () => {
          console.error('Authentication failed')
          this.permissions.write = false
          this.permissions.read = false
        },
        onSynced: () => {
          console.log('Document synced')
          this.loading = false
          this.$emit('document-loaded')
        }
      })
      console.log('Provider created for: ', `pages:${this.page.key}:${this.version.key}:0`)
      this.provider.on('status', event => {
        this.hpstatus = event.status
      })
    }
  }
}
</script>
