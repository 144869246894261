import { mergeAttributes, Mark } from '@tiptap/core'
export const SmallText = Mark.create({
  name: 'small',

  /*content: 'text*',
    marks: '',
    group: 'block',
    defining: true,*/

  addOptions() {
    return {
      HTMLAttributes: {}
    }
  },

  parseHTML() {
    return [
      {
        tag: 'small'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['small', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setSmall: () => ({ commands }) => {
        return commands.setMark(this.name)
      },
      toggleSmall: () => ({ commands }) => {
        return commands.toggleMark(this.name)
      },
      unsetSmall: () => ({ commands }) => {
        return commands.unsetMark(this.name)
      }
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-s': () => this.editor.commands.toggleSmall(),
      'Mod-S': () => this.editor.commands.toggleSmall()
    }
  }
})
