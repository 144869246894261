import { Extension } from '@tiptap/core'
//import { Plugin, PluginKey } from '@tiptap/pm/state'
export const CommentsExtension = Extension.create({
  name: 'comments',

  addOptions() {
    return {
      types: ['heading', 'paragraph']
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          'data-comments': {
            default: 0,
            keepOnSplit: false,
            parseHTML: element => element.getAttribute('data-comments') || 0,
            renderHTML: attributes => {
              if (attributes['data-comments'] > 0) {
                return { 'data-comments': attributes['data-comments'] }
              }
              return {} //'data-comments': attributes['data-comments']
            }
          }
        }
      }
    ]
  }

  /*addProseMirrorPlugins() {
    const extensionThis = this
    return [
      new Plugin({
        key: new PluginKey('comments'),
        appendTransaction(transactions, oldState, newState) {
          const tr = newState.tr
          let modified = false

          transactions.forEach(transaction => {
            if (transaction.getMeta('split')) {
              const { selection } = newState
              const { $from } = selection
              const nodeType = $from.node().type

              if (extensionThis.options.types.includes(nodeType.name)) {
                tr.setNodeMarkup($from.pos, null, {
                  ...$from.node().attrs,
                  'data-comments': 0
                })
                modified = true
              }
            }
          })

          return modified ? tr : null
        }
      })
    ]
  }*/
})
